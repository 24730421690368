














































































































































































































































.relation_jg_dialog {
  .el-dialog--center .el-dialog__body {
    padding: 25px 20px 30px;
  }
  .el-table--border {
    border-bottom: 1px solid #ebeef5;
    th {
      border-right: 1px solid #ebeef5;
    }
  }
  .table_box {
    width: 42%;
    .table_box_top {
      margin-bottom: 10px;
    }
    .list_top {
      padding: 15px;
      text-align: center;
      border: 1px solid #ebeef5;
      border-bottom: 0;
    }
    .list_search {
      border: 1px solid #ebeef5;
      border-top: 0;
      border-bottom: 0;
      padding: 0 10px 15px;
    }
  }
  // 左右按钮操作
  .btn_arrow {
    padding: 1.25rem;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .relation_page {
    .el-pagination__sizes {
      .el-select {
        top: -3px;
      }
    }
  }
}
